<template>
  <div class="article_list_box">
    <div class="img_title">
      <div style="width: 1200px; margin: 0 auto; position: relative">
        <span class="box_title">
          {{ pageData.name ? pageData.name : columnName }}
        </span>
      </div>
      <img
        style="width: 100%; height: 100%"
        src="../../assets/img/title_1.jpg"
        alt=""
      />
    </div>
    <div class="article_list">
      <div class="article_list_left" v-if="is_show_menu !== '0____________'">
        <div class="article_list_title">{{ pageData.parentName }}</div>
        <el-menu
          :unique-opened="true"
          :default-active="pageData.id"
          class="el-menu-vertical-demo"
          background-color="#F2F2F2"
          text-color="#000"
          active-text-color="#C21D14"
        >
          <div v-for="item in nav_list" :key="item.columnId">
            <el-submenu :index="item.columnId + ''" v-if="item.children.length">
              <template slot="title">
                <span>{{ item.name }}</span>
              </template>
              <el-menu-item
                v-for="data in item.children"
                :key="data.columnId"
                :index="data.columnId + ''"
                @click="handle_skip(data)"
                ><span class="dian"></span>{{ data.name }}</el-menu-item
              >
            </el-submenu>
            <el-menu-item
              :index="item.columnId + ''"
              v-else
              @click="handle_skip(item)"
            >
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>
          </div>
        </el-menu>
      </div>
      <div class="article_menu">
        <el-menu
          ref="menu"
          :unique-opened="true"
          class="el-menu-demo"
          text-color="#000"
          active-text-color="#C21D14"
        >
          <el-submenu index="2" class="el-menu_">
            <template slot="title">{{
              pageData.parentName ? pageData.parentName : columnName
            }}</template>
            <div v-for="item in nav_list" :key="item.id">
              <el-submenu
                :index="item.id + ''"
                v-if="item.children.length"
                class="my_submenu"
              >
                <template slot="title">
                  <span>{{ item.name }}</span>
                </template>
                <el-menu-item
                  v-for="data in item.children"
                  :key="data.id"
                  :index="data.id + ''"
                  @click="handle_skip(data)"
                  ><span class="dian1__"></span>{{ data.name }}</el-menu-item
                >
              </el-submenu>
              <el-menu-item
                :index="item.id + ''"
                v-else
                @click="handle_skip(item)"
              >
                <span slot="title">{{ item.name }}</span>
              </el-menu-item>
            </div>
          </el-submenu>
        </el-menu>
      </div>
      <div class="article_list_right">
        <div class="article_list_right_top">
          <div class="article_list_right_title">
            {{ pageData.name ? pageData.name : columnName }}
          </div>
          <div class="article_list_right_tab">
            首页 >
            {{ pageData.parentName ? pageData.parentName : "" }}
            {{ pageData.parentName ? `>${pageData.name}` : columnName }}
          </div>
        </div>
        <div class="article_list_right_List">
          <div
            class="article_list_right_item1"
            v-for="item in dataList"
            :key="item.id"
            @click="handle_details(item)"
          >
            <div class="article_list_right_item_text" style="width:750px">{{ item.title }}</div>
            <div class="article_list_right_item_time">
              {{ handle_publishTime(item.publishTime) }}
            </div>
          </div>
        </div>
        <el-pagination
          style="text-align: center; margin-top: 30px"
          prev-text="上一页"
          next-text="下一页"
          background
          :page-size="20"
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      is_show_menu: this.$route.query.id,
      dataList: [],
      nav_list: [],
      pageData: {},
      total: 0,
      columnName: "",
    };
  },
  watch: {
    $route: "getId",
  },
  computed: {
    handle_publishTime() {
      return (data) => data.split(' ')[0]
    }
  },
  created() {
    this.getList();
  },
  mounted() {
    this.columnName = this.$route.query.name;
    if (this.$route.query.id !== "0____________") {
      this.getData();
    }
  },
  methods: {
    handleCurrentChange(val) {
      if (this.$route.query.name) {
        this.$axios({
          method: "get",
          headers: {
            'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
          },
          url: `/api/web/cms/list_by_column_name?columnName=${this.$route.query.name
            }&start=${(val - 1) * 20}&limit=${val * 20}`,
        }).then((res) => {
          if (res) {
            this.dataList = res.data.rows;
            this.total = res.data.total;
          } else {
            this.$message.error("这个栏目中没有内容");
          }
        });
      } else {
        this.$axios({
          method: "get",
          headers: {
            'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
          },
          url: `/api/web/cms/list_by_column_id?columnId=${this.$route.query.column_id
            }&start=${(val - 1) * 20}&limit=${val * 20}`,
        }).then((res) => {
          if (res) {
            this.dataList = res.data.rows;
            this.total = res.data.total;
          } else {
            this.$message.error("这个栏目中没有内容");
          }
        });
      }
    },
    handle_skip(data) {
      this.$refs['menu'].close('2')
      this.$router.push({
        path: data.href,
        query: {
          column_id: data.columnId,
          id: data.id,
        },
      });
    },
    handle_details(data) {
      if (data.title === '居间公示') {
        this.$router.push({
          path: "/intermediary",
          query: {
            my_id: data.id,
          },
        });
      } else if (data.hasLink) {
        window.open(data.link)
      } else {
        this.$router.push({
          path: "/details",
          query: {
            name: this.$route.query.name,
            id: this.$route.query.id,
            my_id: data.id,
          },
        });
      }
    },
    getList() {
      if (this.$route.query.name) {
        this.$axios({
          method: "get",
          headers: {
            'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
          },
          url: `/api/web/cms/list_by_column_name?columnName=${this.$route.query.name}&start=0&limit=20`,
        }).then((res) => {
          if (res) {
            this.dataList = res.data.rows;
            this.total = res.data.total;
          } else {
            this.$message.error("这个栏目中没有内容");
          }
        });
      } else {
        this.$axios({
          method: "get",
          headers: {
            'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
          },
          url: `/api/web/cms/list_by_column_id?columnId=${this.$route.query.column_id}&start=0&limit=20`,
        }).then((res) => {
          if (res) {
            this.dataList = res.data.rows;
            this.total = res.data.total;
          } else {
            this.$message.error("这个栏目中没有内容");
          }
        });
      }
    },
    getId() {
      this.is_show_menu = this.$route.query.id;
      if (this.$route.query.id !== "0____________") {
        this.getData();
      }
      this.getList();
    },
    getData() {
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/navigation/by_id?id=${this.$route.query.id}`,
      }).then((response) => {
        this.pageData = {
          name: response.data.data.name,
          id: this.$route.query.column_id,
        };
      });
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/navigation/tree_by_leaf?navId=${this.$route.query.id}`,
      }).then((res) => {
        this.pageData = {
          ...this.pageData,
          parentName: res.data[0].name,
        };
        this.nav_list = [...res.data[0].children];
      });
    },
  },
};
</script>
<style scoped>
.article_list_right_List {
  min-height: 265px;
}
.box_title {
  position: absolute;
  top: 84px;
  left: 23px;
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}
.article_list_right_item_text {
  height: 59px;
  line-height: 59px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.article_list_right_item_text:hover {
  color: #c21d14;
}
.article_list_right_item_time {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  height: 59px;
  line-height: 59px;
}
.article_list_right_item1 {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px #d3d3d3 dashed;
  cursor: pointer;
}
.article_list_right_tab {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 66px;
}
.article_list_right_title {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 66px;
  border-bottom: #c21d14 3px solid;
}
.article_list_left {
  margin-right: 40px;
}
.article_list_right {
  flex: 1;
}
.article_list_right_top {
  border-bottom: #e5e5e5 1px solid;
  display: flex;
  justify-content: space-between;
}
.dian {
  display: inline-block;
  width: 4px;
  height: 4px;
  background: #d3d3d3;
  border-radius: 50%;
  margin-right: 10px;
  position: absolute;
  left: 9%;
  top: 45%;
}
.article_list_title {
  width: 280px;
  height: 61px;
  background: #c21d14;
  border-radius: 10px 10px 0px 0px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: left;
  padding-left: 29px;
  line-height: 61px;
}
.article_list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  width: 1200px;
  margin: 20px auto 94px auto;
}
.img_title {
  width: 100%;
  background: #ccc;
}
.article_list_box {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
</style>
